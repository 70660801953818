<template>
  <div class="auth-page">
    <b-container>
      <b-card class="widget-auth mx-auto" header-bg-variant="custom">
        <template #header>
          <h4 class="text-white">{{ $t('menu.forgottenPassword') }}</h4>
        </template>
        <form class="mt" @submit.prevent="submit">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{ errorMessage }}</b-alert>
          <b-alert class="alert-sm" variant="success" :show="!!successMessage">{{ successMessage }}</b-alert>
          <div class="form-group">
            <h5>{{ $t("general.passwordReset.DemandResetPasswordEmail") }}</h5>
            <input
              class="form-control no-border"
              ref="email"
              required
              type="email"
              name="email"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <b-button
            type="submit"
            size="sm"
            class="mb-3"
            variant="custom"
          >{{ $t("general.passwordReset.sendResetRequest") }}</b-button>
        </form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
  data() {
    return {
      email: null,
      errorMessage: null,
    }
  },
  methods: {
    ...mapActions("auth", { sendPasswordReset: "sendPasswordReset" }),
    submit() {
      let email = this.email
      let lang = this.$i18n.locale
      let aspNetRole = this.$staticVariables.getAspNetRole()
      this.sendPasswordReset({ email, lang, aspNetRole })
        .then(() => {
          this.successMessage = this.$t("general.reset_email_sent")
        })
        .catch((error) => {
          console.log(error)
          // eslint-disable-next-line no-console
          var type = error
          switch (type) {
            case "access_level":
              this.errorMessage = this.$t("general.user_access_level")
              break
            case "invalid_company":
              this.errorMessage = this.$t("general.user_company_error")
              break
          }
        })
    },
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
}
</script>

<style>
</style>